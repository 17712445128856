// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3csvg width=%27100%25%27 height=%27100%25%27 xmlns=%27http://www.w3.org/2000/svg%27%3e%3crect width=%27100%25%27 height=%27100%25%27 fill=%27none%27 rx=%2713%27 ry=%2713%27 stroke=%27%23D5D2DCFF%27 stroke-width=%273%27 stroke-dasharray=%276%2c 14%27 stroke-dashoffset=%278%27 stroke-linecap=%27square%27/%3e%3c/svg%3e", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/** @format */

.carousel-container {
  position: relative;
  max-width: 600px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousels {
  overflow: hidden;
  height: -moz-fit-content;
  height: fit-content;
  width: 310px;
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.carousel-slide {
  width: 100%;
  max-width: 98px;
  min-height: 100px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  border-radius: 13px;
  padding: 4px;
  position: relative;
}

.carousel-slide > #edit {
  position: absolute;
  bottom: 10px;
  right: 35px;
  color: #fff;
  background: black;
}

.carousel-slide > #delete {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: black;
  color: #fff;
}

/* .carousel-slide img {
  width: 100%;
  height: inherit;
}

.carousel-slide.active {
  opacity: 1;
}

button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
} */
`, "",{"version":3,"sources":["webpack://./src/components/Carousel/index.css"],"names":[],"mappings":"AAAA,aAAa;;AAEb;EACE,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,wBAAmB;EAAnB,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,iBAAiB;EACjB,yDAAsT;EACtT,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,iBAAiB;EACjB,WAAW;AACb;;AAEA;;;;;;;;;;;;;;;;;;;;;;;;;GAyBG","sourcesContent":["/** @format */\n\n.carousel-container {\n  position: relative;\n  max-width: 600px;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.carousels {\n  overflow: hidden;\n  height: fit-content;\n  width: 310px;\n  display: flex;\n  align-items: center;\n  column-gap: 8px;\n}\n\n.carousel-slide {\n  width: 100%;\n  max-width: 98px;\n  min-height: 100px;\n  background-image: url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='13' ry='13' stroke='%23D5D2DCFF' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='8' stroke-linecap='square'/%3e%3c/svg%3e\");\n  border-radius: 13px;\n  padding: 4px;\n  position: relative;\n}\n\n.carousel-slide > #edit {\n  position: absolute;\n  bottom: 10px;\n  right: 35px;\n  color: #fff;\n  background: black;\n}\n\n.carousel-slide > #delete {\n  position: absolute;\n  bottom: 10px;\n  right: 10px;\n  background: black;\n  color: #fff;\n}\n\n/* .carousel-slide img {\n  width: 100%;\n  height: inherit;\n}\n\n.carousel-slide.active {\n  opacity: 1;\n}\n\nbutton {\n  position: absolute;\n  top: 50%;\n  transform: translateY(-50%);\n  font-size: 24px;\n  background: none;\n  border: none;\n  cursor: pointer;\n}\n\n.prev {\n  left: 10px;\n}\n\n.next {\n  right: 10px;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
