// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../public/Horta demo.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * fonts.css
 *
 * @format
 */

@font-face {
  font-family: 'Horta';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('opentype');
  /* You can use other format types like 'woff', 'woff2', 'truetype', etc. */
}
`, "",{"version":3,"sources":["webpack://./src/fonts.css"],"names":[],"mappings":"AAAA;;;;EAIE;;AAEF;EACE,oBAAoB;EACpB,+DAAwD;EACxD,0EAA0E;AAC5E","sourcesContent":["/**\n * fonts.css\n *\n * @format\n */\n\n@font-face {\n  font-family: 'Horta';\n  src: url('../public/Horta\\ demo.otf') format('opentype');\n  /* You can use other format types like 'woff', 'woff2', 'truetype', etc. */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
