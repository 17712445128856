// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/** @format */

.save-button {
  font-size: large;
  border-radius: 10px;
  background: linear-gradient(135deg, #2a5b11 0%, #457920 100%);
  color: var(--color-white);
  border: 0;
  transition: 0.5s;
  max-width: 500px;
  width: 100%;
  height: 65px;
  font-weight: 500;
}

.save-button:hover {
  background: var(--color-white);
  color: #022a06;
  border: 1px solid #335918;
}

.cancle-modal {
  position: absolute;
  top: 17px;
  right: 21px;
  cursor: pointer;
  color: #d8d8d8;
}

.cancle-modal-custom {
  position: absolute;
  top: 17px;
  right: 21px;
  cursor: pointer;
  background: #d8d8d8;
  color: #4c4f54;
  width: 30px;
  height: 29px;
  text-align: center;
  border-radius: 50%;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/AddUploadImageModal/index.css"],"names":[],"mappings":"AAAA,aAAa;;AAEb;EACE,gBAAgB;EAChB,mBAAmB;EACnB,6DAA6D;EAC7D,yBAAyB;EACzB,SAAS;EACT,gBAAgB;EAChB,gBAAgB;EAChB,WAAW;EACX,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,8BAA8B;EAC9B,cAAc;EACd,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,eAAe;EACf,mBAAmB;EACnB,cAAc;EACd,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":["/** @format */\n\n.save-button {\n  font-size: large;\n  border-radius: 10px;\n  background: linear-gradient(135deg, #2a5b11 0%, #457920 100%);\n  color: var(--color-white);\n  border: 0;\n  transition: 0.5s;\n  max-width: 500px;\n  width: 100%;\n  height: 65px;\n  font-weight: 500;\n}\n\n.save-button:hover {\n  background: var(--color-white);\n  color: #022a06;\n  border: 1px solid #335918;\n}\n\n.cancle-modal {\n  position: absolute;\n  top: 17px;\n  right: 21px;\n  cursor: pointer;\n  color: #d8d8d8;\n}\n\n.cancle-modal-custom {\n  position: absolute;\n  top: 17px;\n  right: 21px;\n  cursor: pointer;\n  background: #d8d8d8;\n  color: #4c4f54;\n  width: 30px;\n  height: 29px;\n  text-align: center;\n  border-radius: 50%;\n  font-weight: 500;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
