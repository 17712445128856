// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/** @format */

.category-modal > span {
  font-family: sans-serif;
  font-weight: 700;
  color: #1f1b2d;
  font-size: 32px;
}

.category-field {
  display: flex;
  justify-content: space-between;
}
.category-field > input {
  max-width: 450px;
}

.modal-confirm > button {
  padding: 20px 69px;
  background: linear-gradient(135deg, #2a5b11 0%, #457920 100%);
  color: #ffff;
  border-radius: 8px;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: 30px;
}

/* media query here */

@media (max-width: 500px) {
  .category-field {
    flex-direction: column;
    height: 125px;
  }
}

@media (max-width: 425px) {
  .modal-confirm > button {
    margin-inline: auto;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/SubCategoryPoupop/index.css"],"names":[],"mappings":"AAAA,aAAa;;AAEb;EACE,uBAAuB;EACvB,gBAAgB;EAChB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,6DAA6D;EAC7D,YAAY;EACZ,kBAAkB;EAClB,uBAAkB;EAAlB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA,qBAAqB;;AAErB;EACE;IACE,sBAAsB;IACtB,aAAa;EACf;AACF;;AAEA;EACE;IACE,mBAAmB;EACrB;AACF","sourcesContent":["/** @format */\n\n.category-modal > span {\n  font-family: sans-serif;\n  font-weight: 700;\n  color: #1f1b2d;\n  font-size: 32px;\n}\n\n.category-field {\n  display: flex;\n  justify-content: space-between;\n}\n.category-field > input {\n  max-width: 450px;\n}\n\n.modal-confirm > button {\n  padding: 20px 69px;\n  background: linear-gradient(135deg, #2a5b11 0%, #457920 100%);\n  color: #ffff;\n  border-radius: 8px;\n  width: fit-content;\n  margin-left: 30px;\n}\n\n/* media query here */\n\n@media (max-width: 500px) {\n  .category-field {\n    flex-direction: column;\n    height: 125px;\n  }\n}\n\n@media (max-width: 425px) {\n  .modal-confirm > button {\n    margin-inline: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
