// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/** @format */

.categories-form > input {
  margin-top: 10px;
  width: 100%;
  border: 1px solid #d5d2dc;
  border-radius: 8px;
  height: 55px;
  padding-inline: 18px;
}

.categories-form > button {
  background: linear-gradient(135deg, #2a5b11 0%, #457920 100%);
  width: 100%;
  padding: 15px;
  border: 0;
  color: white;
  border-radius: 8px;
}
.categorie-section {
  width: inherit;
  height: inherit;
  overflow: auto;
  margin-top: 10px;
}
.categories-list {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.description > h5 {
  text-align: left;
  color: #464e5f;
}

.description > h6 {
  color: #b5b5c3;
}

.categories-list > span {
  color: #b5b5c3;
  cursor: pointer;
}

.categories-list > span:hover {
  color: #ed341b;
}

td > span {
  color: #b5b5c3;
  cursor: pointer;
}

td > span:hover {
  color: #ed341b;
}
span > #edit {
  color: #b5b5c3;
  cursor: pointer;
}

span > #edit :hover {
  color: blue;
}
`, "",{"version":3,"sources":["webpack://./src/components/CategoriesModal/index.css"],"names":[],"mappings":"AAAA,aAAa;;AAEb;EACE,gBAAgB;EAChB,WAAW;EACX,yBAAyB;EACzB,kBAAkB;EAClB,YAAY;EACZ,oBAAoB;AACtB;;AAEA;EACE,6DAA6D;EAC7D,WAAW;EACX,aAAa;EACb,SAAS;EACT,YAAY;EACZ,kBAAkB;AACpB;AACA;EACE,cAAc;EACd,eAAe;EACf,cAAc;EACd,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,wBAAmB;EAAnB,mBAAmB;EACnB,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;AACA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,WAAW;AACb","sourcesContent":["/** @format */\n\n.categories-form > input {\n  margin-top: 10px;\n  width: 100%;\n  border: 1px solid #d5d2dc;\n  border-radius: 8px;\n  height: 55px;\n  padding-inline: 18px;\n}\n\n.categories-form > button {\n  background: linear-gradient(135deg, #2a5b11 0%, #457920 100%);\n  width: 100%;\n  padding: 15px;\n  border: 0;\n  color: white;\n  border-radius: 8px;\n}\n.categorie-section {\n  width: inherit;\n  height: inherit;\n  overflow: auto;\n  margin-top: 10px;\n}\n.categories-list {\n  width: 100%;\n  height: fit-content;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-top: 20px;\n}\n\n.description > h5 {\n  text-align: left;\n  color: #464e5f;\n}\n\n.description > h6 {\n  color: #b5b5c3;\n}\n\n.categories-list > span {\n  color: #b5b5c3;\n  cursor: pointer;\n}\n\n.categories-list > span:hover {\n  color: #ed341b;\n}\n\ntd > span {\n  color: #b5b5c3;\n  cursor: pointer;\n}\n\ntd > span:hover {\n  color: #ed341b;\n}\nspan > #edit {\n  color: #b5b5c3;\n  cursor: pointer;\n}\n\nspan > #edit :hover {\n  color: blue;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
