// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/** @format */

.table-subContainer {
  height: calc(100vh - 145px);
}
.justifyBetween {
  display: flex;
  justify-content: space-between;
  height: 50px;
}
.searchBar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.searchBar > input {
  border: 0;
  border-radius: 30px;
  width: 300px;
  height: 45px;
  padding: 0px 36px;
  outline: 0;
  background: #f4f7fe;
  visibility: hidden;
}

.searchBar > #search {
  position: absolute;
  top: 18px;
  left: 15px;
  color: #2b3674;
  visibility: hidden;
}
.searchBar > #arrow {
  position: absolute;
  top: 8px;
  right: 15px;
  color: #9691a4;
}
@media (max-width: 667px) {
  .searchBar > input {
    width: 48vw;
  }
}

@media (max-width: 425px) {
  .justifyBetween {
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
  }
  .searchBar > input {
    width: 48vw;
  }
}

@media (max-width: 375px) {
  .searchBar > input {
    width: 38vw;
  }
}
`, "",{"version":3,"sources":["webpack://./src/routes/ManageTeam/index.css"],"names":[],"mappings":"AAAA,aAAa;;AAEb;EACE,2BAA2B;AAC7B;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,YAAY;AACd;AACA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;AACA;EACE,SAAS;EACT,mBAAmB;EACnB,YAAY;EACZ,YAAY;EACZ,iBAAiB;EACjB,UAAU;EACV,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,UAAU;EACV,cAAc;EACd,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,QAAQ;EACR,WAAW;EACX,cAAc;AAChB;AACA;EACE;IACE,WAAW;EACb;AACF;;AAEA;EACE;IACE,8BAA8B;IAC9B,sBAAsB;IACtB,mBAAmB;EACrB;EACA;IACE,WAAW;EACb;AACF;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":["/** @format */\n\n.table-subContainer {\n  height: calc(100vh - 145px);\n}\n.justifyBetween {\n  display: flex;\n  justify-content: space-between;\n  height: 50px;\n}\n.searchBar {\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n.searchBar > input {\n  border: 0;\n  border-radius: 30px;\n  width: 300px;\n  height: 45px;\n  padding: 0px 36px;\n  outline: 0;\n  background: #f4f7fe;\n  visibility: hidden;\n}\n\n.searchBar > #search {\n  position: absolute;\n  top: 18px;\n  left: 15px;\n  color: #2b3674;\n  visibility: hidden;\n}\n.searchBar > #arrow {\n  position: absolute;\n  top: 8px;\n  right: 15px;\n  color: #9691a4;\n}\n@media (max-width: 667px) {\n  .searchBar > input {\n    width: 48vw;\n  }\n}\n\n@media (max-width: 425px) {\n  .justifyBetween {\n    justify-content: space-between;\n    flex-direction: column;\n    align-items: center;\n  }\n  .searchBar > input {\n    width: 48vw;\n  }\n}\n\n@media (max-width: 375px) {\n  .searchBar > input {\n    width: 38vw;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
