// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/** @format */

.dropdown-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  row-gap: 15px;
  column-gap: 15px;
}

.product-tag {
  width: 100%;
  height: 110px;
  border: 1px solid #ccc;
  padding: 15px;
}

.product-url {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product-url > span {
  color: #454056;
}

#product-url {
  border: 1px solid #d5d2dc;
  border-radius: 13px;
  width: 300px;
  height: 45px;
}

#product-url:focus {
  outline: none;
}
`, "",{"version":3,"sources":["webpack://./src/routes/AddProduct/index.css"],"names":[],"mappings":"AAAA,aAAa;;AAEb;EACE,aAAa;EACb,8BAA8B;EAC9B,WAAW;EACX,eAAe;EACf,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,aAAa;AACf","sourcesContent":["/** @format */\n\n.dropdown-section {\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n  flex-wrap: wrap;\n  row-gap: 15px;\n  column-gap: 15px;\n}\n\n.product-tag {\n  width: 100%;\n  height: 110px;\n  border: 1px solid #ccc;\n  padding: 15px;\n}\n\n.product-url {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.product-url > span {\n  color: #454056;\n}\n\n#product-url {\n  border: 1px solid #d5d2dc;\n  border-radius: 13px;\n  width: 300px;\n  height: 45px;\n}\n\n#product-url:focus {\n  outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
