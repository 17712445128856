// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * CustomDropdown.css
 *
 * @format
 */

/* .custom-dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: -moz-fit-content;
  min-width: fit-content;
  height: 52px;
} */

.dropdown-button {
  cursor: pointer;
  padding: 14px 1.8vw;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
  color: #11142d;
  font-weight: 700;
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.dropdown-button.open {
  border-radius: 10px 10px 0 0;
}

.dropdown-list {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  display: none;
  border: 1px solid #ccc;
  border-top: none;
  background-color: #fff;
  border-radius: 0 0 10px 10px;
  z-index: 1;
  overflow: scroll;
  height: 300px;
}

.dropdown-list.open {
  display: block;
  z-index: 2;
}

.dropdown-list li {
  padding: 10px;
  cursor: pointer;
  text-align: center;
  color: #11142d;
  font-weight: 700;
  border-bottom: 1px solid lightgray;
}

.dropdown-list li:hover {
  background-color: #f0f0f0;
}
`, "",{"version":3,"sources":["webpack://./src/components/SelectProductDropdown/index.css"],"names":[],"mappings":"AAAA;;;;EAIE;;AAEF;;;;;;;GAOG;;AAEH;EACE,eAAe;EACf,mBAAmB;EACnB,sBAAsB;EACtB,mBAAmB;EACnB,sBAAsB;EACtB,cAAc;EACd,gBAAgB;EAChB,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,2BAA2B;EAC3B,sBAAsB;AACxB;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,gBAAgB;EAChB,SAAS;EACT,UAAU;EACV,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,QAAQ;EACR,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,sBAAsB;EACtB,4BAA4B;EAC5B,UAAU;EACV,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,cAAc;EACd,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,eAAe;EACf,kBAAkB;EAClB,cAAc;EACd,gBAAgB;EAChB,kCAAkC;AACpC;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["/**\n * CustomDropdown.css\n *\n * @format\n */\n\n/* .custom-dropdown {\n  position: relative;\n  display: inline-block;\n  width: 100%;\n  min-width: -moz-fit-content;\n  min-width: fit-content;\n  height: 52px;\n} */\n\n.dropdown-button {\n  cursor: pointer;\n  padding: 14px 1.8vw;\n  border: 1px solid #ccc;\n  border-radius: 10px;\n  background-color: #fff;\n  color: #11142d;\n  font-weight: 700;\n  -webkit-user-select: none;\n  user-select: none;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  min-width: -moz-fit-content;\n  min-width: fit-content;\n}\n\n.dropdown-button.open {\n  border-radius: 10px 10px 0 0;\n}\n\n.dropdown-list {\n  list-style: none;\n  margin: 0;\n  padding: 0;\n  position: absolute;\n  top: 100%;\n  left: 0;\n  right: 0;\n  display: none;\n  border: 1px solid #ccc;\n  border-top: none;\n  background-color: #fff;\n  border-radius: 0 0 10px 10px;\n  z-index: 1;\n  overflow: scroll;\n  height: 300px;\n}\n\n.dropdown-list.open {\n  display: block;\n  z-index: 2;\n}\n\n.dropdown-list li {\n  padding: 10px;\n  cursor: pointer;\n  text-align: center;\n  color: #11142d;\n  font-weight: 700;\n  border-bottom: 1px solid lightgray;\n}\n\n.dropdown-list li:hover {\n  background-color: #f0f0f0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
