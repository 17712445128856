// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f5f5f5;
  text-align: center;
}

.not-found-heading {
  font-size: 6rem;
  color: var(--reject-solid-color);
  margin-bottom: 0.5rem;
}

.not-found-text {
  font-size: 1.5rem;
  color: var(--reject-solid-color);
}
`, "",{"version":3,"sources":["webpack://./src/routes/NotFoundPage/index.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,gCAAgC;EAChC,qBAAqB;AACvB;;AAEA;EACE,iBAAiB;EACjB,gCAAgC;AAClC","sourcesContent":[".not-found-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n  background-color: #f5f5f5;\n  text-align: center;\n}\n\n.not-found-heading {\n  font-size: 6rem;\n  color: var(--reject-solid-color);\n  margin-bottom: 0.5rem;\n}\n\n.not-found-text {\n  font-size: 1.5rem;\n  color: var(--reject-solid-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
