// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/** @format */

.select-option {
  padding: 13.5px 6px;
  color: #ffff;
  background: #a2ba1e;
  border: 0;
  border-bottom: 3px solid #a2ba1e;
  border-radius: 0px 8px 8px 0px;
}

.btn-align-center {
  display: flex;
  align-items: center;
}

.btn-align-center > span {
  background: #335918;
  color: #fff;
  padding: 14px 25px;
  border-radius: 8px 0px 0px 8px;
}

.btn-align-center > span:hover {
  background: #2f5713;
  color: #fff;
  transition: 0.2s;
  cursor: pointer;
}

/* .head-tr {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.body-tr {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
} */

tr > th {
  text-align: left;
}
tr > td {
  text-align: left;
}
`, "",{"version":3,"sources":["webpack://./src/routes/Leads/index.css"],"names":[],"mappings":"AAAA,aAAa;;AAEb;EACE,mBAAmB;EACnB,YAAY;EACZ,mBAAmB;EACnB,SAAS;EACT,gCAAgC;EAChC,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,WAAW;EACX,kBAAkB;EAClB,8BAA8B;AAChC;;AAEA;EACE,mBAAmB;EACnB,WAAW;EACX,gBAAgB;EAChB,eAAe;AACjB;;AAEA;;;;;;;;;;;;GAYG;;AAEH;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB","sourcesContent":["/** @format */\n\n.select-option {\n  padding: 13.5px 6px;\n  color: #ffff;\n  background: #a2ba1e;\n  border: 0;\n  border-bottom: 3px solid #a2ba1e;\n  border-radius: 0px 8px 8px 0px;\n}\n\n.btn-align-center {\n  display: flex;\n  align-items: center;\n}\n\n.btn-align-center > span {\n  background: #335918;\n  color: #fff;\n  padding: 14px 25px;\n  border-radius: 8px 0px 0px 8px;\n}\n\n.btn-align-center > span:hover {\n  background: #2f5713;\n  color: #fff;\n  transition: 0.2s;\n  cursor: pointer;\n}\n\n/* .head-tr {\n  width: 100%;\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n}\n\n.body-tr {\n  width: 100%;\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n} */\n\ntr > th {\n  text-align: left;\n}\ntr > td {\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
