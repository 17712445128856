// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/** @format */

.screen {
  width: 100vw;
  height: 100vh;
  display: flex;
}

.main-content {
  padding-left: 290px;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  position: relative;
}

.page-content {
  padding: 15px 48px;
  height: 95vh;
  overflow: auto;
}

.main-content-hide {
  padding-left: 101px;
  width: 100%;
  transition: 0.3s;
}

/* today 27/07/2023 */

.small-main-content {
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  position: relative;
  transition: 0.3s;
}

/* media query here */

@media (max-width: 992px) {
  .main-content {
    padding-left: 0px;
    overflow: hidden;
  }
  .main-content-hide {
    padding-left: 0px;
    width: 100%;
    transition: 0.3s;
  }

  .small-main-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    position: relative;
    padding-left: 0px;
    overflow: hidden;
  }
}

@media (max-width: 700px) {
  .page-content {
    padding: 15px 18px;
    height: 86vh;
    overflow: auto;
  }
}
`, "",{"version":3,"sources":["webpack://./src/routes/Account/index.css"],"names":[],"mappings":"AAAA,aAAa;;AAEb;EACE,YAAY;EACZ,aAAa;EACb,aAAa;AACf;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,SAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,mBAAmB;EACnB,WAAW;EACX,gBAAgB;AAClB;;AAEA,qBAAqB;;AAErB;EACE,gBAAgB;EAChB,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA,qBAAqB;;AAErB;EACE;IACE,iBAAiB;IACjB,gBAAgB;EAClB;EACA;IACE,iBAAiB;IACjB,WAAW;IACX,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,kBAAkB;IAClB,iBAAiB;IACjB,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,kBAAkB;IAClB,YAAY;IACZ,cAAc;EAChB;AACF","sourcesContent":["/** @format */\n\n.screen {\n  width: 100vw;\n  height: 100vh;\n  display: flex;\n}\n\n.main-content {\n  padding-left: 290px;\n  overflow: hidden;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  flex: 1 1 0%;\n  position: relative;\n}\n\n.page-content {\n  padding: 15px 48px;\n  height: 95vh;\n  overflow: auto;\n}\n\n.main-content-hide {\n  padding-left: 101px;\n  width: 100%;\n  transition: 0.3s;\n}\n\n/* today 27/07/2023 */\n\n.small-main-content {\n  overflow: hidden;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  flex: 1 1;\n  position: relative;\n  transition: 0.3s;\n}\n\n/* media query here */\n\n@media (max-width: 992px) {\n  .main-content {\n    padding-left: 0px;\n    overflow: hidden;\n  }\n  .main-content-hide {\n    padding-left: 0px;\n    width: 100%;\n    transition: 0.3s;\n  }\n\n  .small-main-content {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    flex: 1 1;\n    position: relative;\n    padding-left: 0px;\n    overflow: hidden;\n  }\n}\n\n@media (max-width: 700px) {\n  .page-content {\n    padding: 15px 18px;\n    height: 86vh;\n    overflow: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
