// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/** @format */

.product-tag > span {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

/* Styling for unchecked checkboxes */
#product-check {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid #bbb7c5;
  border-radius: 4px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  margin-right: 8px;
  cursor: pointer;
}

/* Styling for checked checkboxes */
#product-check:checked::before {
  content: '\\2713';
  font-size: 9px;
  color: red;
  background-color: #fff;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  border: 2px solid red;
  width: inherit;
  height: inherit;
  text-align: center;
}

/* Optional: Hover effect */
#product-check:hover {
  border-color: red;
}
`, "",{"version":3,"sources":["webpack://./src/components/ProductTagCheckBox/index.css"],"names":[],"mappings":"AAAA,aAAa;;AAEb;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,mBAAmB;AACrB;;AAEA,qCAAqC;AACrC;EACE,gBAAgB;EAChB,wBAAwB;EACxB,qBAAqB;EACrB,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,qBAAqB;EACrB,kBAAkB;EAClB,sBAAsB;EACtB,iBAAiB;EACjB,eAAe;AACjB;;AAEA,mCAAmC;AACnC;EACE,gBAAgB;EAChB,cAAc;EACd,UAAU;EACV,sBAAsB;EACtB,kBAAkB;EAClB,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,iBAAiB;EACjB,qBAAqB;EACrB,cAAc;EACd,eAAe;EACf,kBAAkB;AACpB;;AAEA,2BAA2B;AAC3B;EACE,iBAAiB;AACnB","sourcesContent":["/** @format */\n\n.product-tag > span {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: center;\n}\n\n/* Styling for unchecked checkboxes */\n#product-check {\n  appearance: none;\n  -webkit-appearance: none;\n  -moz-appearance: none;\n  width: 16px;\n  height: 16px;\n  border: 2px solid #bbb7c5;\n  border-radius: 4px;\n  display: inline-block;\n  position: relative;\n  vertical-align: middle;\n  margin-right: 8px;\n  cursor: pointer;\n}\n\n/* Styling for checked checkboxes */\n#product-check:checked::before {\n  content: '\\2713';\n  font-size: 9px;\n  color: red;\n  background-color: #fff;\n  border-radius: 4px;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  font-weight: bold;\n  border: 2px solid red;\n  width: inherit;\n  height: inherit;\n  text-align: center;\n}\n\n/* Optional: Hover effect */\n#product-check:hover {\n  border-color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
