// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/** @format */

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.sliders {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #a6a6a6;
  transition: 0.4s;
}

.sliders:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
}

.switch input:checked + .sliders {
  background-color: #a2ba1e;
}

.switch input:focus + .sliders {
  box-shadow: 0 0 1px #a2ba1e;
}

.switch input:checked + .sliders:before {
  transform: translateX(26px);
}

/* Rounded sliderss */
.sliders.round {
  border-radius: 34px;
}

.sliders.round:before {
  border-radius: 50%;
}
`, "",{"version":3,"sources":["webpack://./src/components/ToggleSwitch/index.css"],"names":[],"mappings":"AAAA,aAAa;;AAEb;EACE,kBAAkB;EAClB,qBAAqB;EACrB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,UAAU;EACV,QAAQ;EACR,SAAS;AACX;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,WAAW;EACX,SAAS;EACT,WAAW;EACX,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,2BAA2B;AAC7B;;AAEA,qBAAqB;AACrB;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["/** @format */\n\n.switch {\n  position: relative;\n  display: inline-block;\n  width: 60px;\n  height: 30px;\n}\n\n.switch input {\n  opacity: 0;\n  width: 0;\n  height: 0;\n}\n\n.sliders {\n  position: absolute;\n  cursor: pointer;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: #a6a6a6;\n  transition: 0.4s;\n}\n\n.sliders:before {\n  position: absolute;\n  content: '';\n  height: 26px;\n  width: 26px;\n  left: 4px;\n  bottom: 2px;\n  background-color: white;\n  transition: 0.4s;\n}\n\n.switch input:checked + .sliders {\n  background-color: #a2ba1e;\n}\n\n.switch input:focus + .sliders {\n  box-shadow: 0 0 1px #a2ba1e;\n}\n\n.switch input:checked + .sliders:before {\n  transform: translateX(26px);\n}\n\n/* Rounded sliderss */\n.sliders.round {\n  border-radius: 34px;\n}\n\n.sliders.round:before {\n  border-radius: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
