// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-css {
    display: flex;
    scroll-snap-type: x mandatory y mandatory;
    width: 100%; /* Make it responsive */
    overflow: auto; /* Allow horizontal scrolling on small screens */
  }
  
  .box-css {
    /* height: 200px; */
    /* width: 200px; */
    /* background: red; */
    /* margin-right: 5px; */
    flex-shrink: 0;
    scroll-snap-align: start;
  }
  




`, "",{"version":3,"sources":["webpack://./src/components/ScrollCrousel/Index.css"],"names":[],"mappings":"AAAA;IAEI,aAAa;IACb,yCAAyC;IACzC,WAAW,EAAE,uBAAuB;IACpC,cAAc,EAAE,gDAAgD;EAClE;;EAEA;IACE,mBAAmB;IACnB,kBAAkB;IAClB,qBAAqB;IACrB,uBAAuB;IACvB,cAAc;IACd,wBAAwB;EAC1B","sourcesContent":[".container-css {\n    overflow: auto;\n    display: flex;\n    scroll-snap-type: x mandatory y mandatory;\n    width: 100%; /* Make it responsive */\n    overflow: auto; /* Allow horizontal scrolling on small screens */\n  }\n  \n  .box-css {\n    /* height: 200px; */\n    /* width: 200px; */\n    /* background: red; */\n    /* margin-right: 5px; */\n    flex-shrink: 0;\n    scroll-snap-align: start;\n  }\n  \n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
