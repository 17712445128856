/** @format */

import React, { useEffect, useRef, useState } from 'react';
import './test.css';
import imagebg from '../../assets/User/image 874.png';
function Test() {
  return (
    <>
      <div className="home-section-2">
        <h1 data-text="PEPE CONES">PEPE CONES</h1>
      </div>
      {/* <img src={imagebg} alt="" className="w-100 " /> */}
    </>
  );
}

export default Test;
