// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/** @format */

.jodit-container:not(.jodit_inline) {
  position: relative;
  width: -webkit-fill-available !important;
  /* height: 530px !important; */
}

.jodit-toolbar__box:not(:empty):not(:empty) {
  background: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  width: -webkit-fill-available;
}

.jodit-workplace + .jodit-status-bar:not(:empty) {
  display: none;
}

.jodit-container:not(.jodit_inline) .jodit-workplace {
  min-height: 450px;
  border-radius: 13px;
}

.jodit-container:not(.jodit_inline) .jodit-wysiwyg {
  min-height: 450px;
  overflow: scroll;
}

.jodit-workplace + .jodit-status-bar:not(:empty) {
  display: none;
}

.jodit-toolbar__box:not(:empty) {
  border-radius: 13px;
}

.jodit-container:not(.jodit_inline) {
  background-color: #f5f5f6;
  border: 1px solid #dadada;
  border-radius: 13px;
}

.jodit-toolbar-editor-collection_mode_horizontal {
  z-index: 1000;
  background: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/components/ReactEditer/index.css"],"names":[],"mappings":"AAAA,aAAa;;AAEb;EACE,kBAAkB;EAClB,wCAAwC;EACxC,8BAA8B;AAChC;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,6BAA6B;AAC/B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,gBAAgB;AAClB","sourcesContent":["/** @format */\n\n.jodit-container:not(.jodit_inline) {\n  position: relative;\n  width: -webkit-fill-available !important;\n  /* height: 530px !important; */\n}\n\n.jodit-toolbar__box:not(:empty):not(:empty) {\n  background: #fff;\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  width: -webkit-fill-available;\n}\n\n.jodit-workplace + .jodit-status-bar:not(:empty) {\n  display: none;\n}\n\n.jodit-container:not(.jodit_inline) .jodit-workplace {\n  min-height: 450px;\n  border-radius: 13px;\n}\n\n.jodit-container:not(.jodit_inline) .jodit-wysiwyg {\n  min-height: 450px;\n  overflow: scroll;\n}\n\n.jodit-workplace + .jodit-status-bar:not(:empty) {\n  display: none;\n}\n\n.jodit-toolbar__box:not(:empty) {\n  border-radius: 13px;\n}\n\n.jodit-container:not(.jodit_inline) {\n  background-color: #f5f5f6;\n  border: 1px solid #dadada;\n  border-radius: 13px;\n}\n\n.jodit-toolbar-editor-collection_mode_horizontal {\n  z-index: 1000;\n  background: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
